.video {
  max-width: 100%;
  width: 100%;
  background-color: var(--theme-elevation-50);
}

.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
