@import '../../_css/common';

.pageRange {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.content {
  display: flex;
  align-items: center;
  margin: 0 var(--base(0.5));
}

.divider {
  margin: 0 2px;
}

.hyperlink {
  display: flex;
}
