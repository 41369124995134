.gutter {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.gutterLeft {
  padding-left: var(--gutter-h);
}

.gutterRight {
  padding-right: var(--gutter-h);
}
