@import "../../_css/common";

.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	// gap: var(--base) calc(var(--base) * 2);
	gap: 16px 32px;

	@include mid-break {
		grid-template-columns: repeat(6, 1fr);
		gap: var(--base) var(--base);
	}
}

.column--oneThird {
	grid-column-end: span 4;
}

.column--half {
	grid-column-end: span 6;
}

.column--twoThirds {
	grid-column-end: span 8;
}

.column--full {
	grid-column-end: span 12;
}

.column {
	p {
		padding-bottom: 16px;
		font-size: 18px;
	}
	@include mid-break {
		grid-column-end: span 6;
	}

	@include small-break {
		grid-column-end: span 6;
	}
}

.link {
	margin-top: var(--base);
}
