@use "../../_css/queries.scss" as *;

$spacer-h: calc(var(--block-padding) / 2);

.callToAction {
	padding-left: $spacer-h;
	padding-right: $spacer-h;
	position: relative;
	// background-color: var(--theme-elevation-100);
	background-color: $color-primary;
	padding: 16px 32px;
	border-radius: 8px;
	font-size: 18px;
	color: var(--theme-elevation-1000);
}

:global([data-theme="dark"]) {
	.callToAction {
		background-color: var(--theme-elevation-0);
		color: var(--theme-elevation-1000);
	}
}

.invert {
	background-color: var(--theme-elevation-1000);
	color: var(--theme-elevation-0);
}

:global([data-theme="dark"]) {
	.invert {
		background-color: var(--theme-elevation-900);
		color: var(--theme-elevation-0);
	}
}

.wrap {
	display: flex;
	gap: $spacer-h;
	align-items: center;

	@include mid-break {
		flex-direction: column;
		align-items: flex-start;
	}
}

.content {
	flex-grow: 1;
}

.linkGroup {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	flex-shrink: 0;

	> * {
		margin-bottom: calc(var(--base) / 2);

		&:last-child {
			margin-bottom: 0;
		}
	}
}
