@import "ui/style/variables/colors.scss";
// Keep these in sync with the breakpoints exported in '../cssVariables.js'

$breakpoint-xs-width: 400px;
$breakpoint-s-width: 768px;
$breakpoint-m-width: 1024px;
$breakpoint-l-width: 1440px;

@mixin extra-small-break {
	@media (max-width: #{$breakpoint-xs-width}) {
		@content;
	}
}

@mixin small-break {
	@media (max-width: #{$breakpoint-s-width}) {
		@content;
	}
}

@mixin mid-break {
	@media (max-width: #{$breakpoint-m-width}) {
		@content;
	}
}

@mixin large-break {
	@media (max-width: #{$breakpoint-l-width}) {
		@content;
	}
}
