@import "../../_css/common";

// this is to make up for the space taken by the fixed header, since the scroll method does not accept an offset parameter
.scrollRef {
	position: absolute;
	left: 0;
	top: calc(var(--base) * -5);
	@include mid-break {
		top: calc(var(--base) * -2);
	}
	margin-bottom: 16px;
}

.introContent {
	position: relative;
	margin-bottom: calc(var(--base) * 2);

	@include mid-break {
		margin-bottom: var(--base);
	}
}

.resultCountWrapper {
	display: flex;
	margin-bottom: calc(var(--base) * 2);

	@include mid-break {
		margin-bottom: var(--base);
	}
}

.pageRange {
	// margin-bottom: var(--base);
	margin-bottom: 16px;

	@include mid-break {
		margin-bottom: var(--base);
	}
}

.list {
	position: relative;
}

.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	width: 100%;
	gap: var(--base) 40px;

	@include mid-break {
		grid-template-columns: repeat(6, 1fr);
		gap: calc(var(--base) / 2) var(--base);
	}
}

.column {
	grid-column-end: span 4;
	margin-right: 8px;

	@include mid-break {
		grid-column-end: span 6;
	}

	@include small-break {
		grid-column-end: span 6;
	}
}

.pagination {
	margin-top: calc(var(--base) * 2);

	@include mid-break {
		margin-top: var(--base);
	}
}
