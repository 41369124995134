@import "../../../../style/variables/text.scss";
@import "../../../../style/variables/colors.scss";

.TextArea {
	@include text-main;
	textarea {
		padding: 0px px(2);
		margin: px(1.5) 0px;
		height: px(5);
		background-color: $color-background;
		border: none;
		width: 100%;
		border-radius: px(0.5);
		min-height: 210px;
		width: 380px;
		min-width: 380px;
		max-width: 380px;
		padding: px(2);
	}
	textarea::placeholder {
		@include text-input-placeholder;
	}
}

.Label {
	@include text-main;
	display: flex;
	flex-direction: row;

	label {
		display: flex;
		margin-top: px(1.5);
		font-size: px(1.5);
		letter-spacing: 0;
		flex-grow: 1;
	}

	.isError {
		display: flex;
		justify-content: flex-end;
		flex-grow: 1;
		text-align: center;
		color: $color-failure;
	}
}

@media screen and (max-width: 800px) {
	.TextArea textarea {
		background-color: $color-background;
		font-family: "proxima-nova";
		padding: px(2);
	}
}
