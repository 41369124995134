@use '../../_css/common.scss' as *;

.postHero {
  display: flex;
  gap: calc(var(--base) * 2);

  @include mid-break {
    flex-direction: column;
    gap: var(--base);
  }
}

.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--base);

  @include mid-break {
    width: 100%;
    gap: calc(var(--base) / 2);
  }
}

.leader {
  @extend %label;
  display: flex;
  gap: calc(var(--base) / 2);
}

.title {
  margin: 0;
}

.warning {
  margin-bottom: calc(var(--base) * 1.5);
}

.meta {
  margin: 0;
}

.description {
  margin: 0;
}

.media {
  width: 50%;

  @include mid-break {
    width: 100%;
  }
}

.mediaWrapper {
  text-decoration: none;
  display: block;
  position: relative;
  aspect-ratio: 5 / 4;
  margin-bottom: calc(var(--base) / 2);
  width: calc(100% + calc(var(--gutter-h) / 2));

  @include mid-break {
    margin-left: calc(var(--gutter-h) * -1);
    width: calc(100% + var(--gutter-h) * 2);
  }
}

.image {
  object-fit: cover;
}

.placeholder {
  background-color: var(--theme-elevation-50);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caption {
  color: var(--theme-elevation-500);
}
