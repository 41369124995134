@import "../../_css/common";

.archiveBlock {
	position: relative;
}

.introContent {
	margin-bottom: calc(var(--base) * 2);
	font-size: 20px;
	font-weight: bold;

	@include mid-break {
		margin-bottom: calc(var(--base) * 2);
	}
}
